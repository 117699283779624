import { Box, Typography } from '@mui/material';
import React, {useEffect} from 'react';
import AddComment from './AddComment';


const CustomProgressBar = ({ className,currentTime,setTimestamp, setCommentModalOpen,markers, duration, ...props }) => {
//   console.log('CustomProgressBar rendered', { className, markers, duration });
useEffect(()=>{

},[currentTime])
    return (
    <div className={`${className} custom-progress-wrapper`} 
    style={{ position: 'relative' }}>
      <div {...props} /> {/* This is the original progress bar */}
      <Typography sx={{alignSelf:'center',color:'#656565'}} >comments</Typography>
      {markers?.map((marker, index) => {
        // console.log(`Position:" ${(marker?.time / duration) * 100}% time`, duration)
        return(
        <div
          key={index}
          className="marker"
          style={{
            position: 'absolute',
            left: `${(marker?.time / duration) * 100}%`,
            top: 0,
            bottom: 0,
            width: '2px',
            backgroundColor: 'red',
            zIndex: 99999,
            cursor:'pointer',

          }}
          onClick={()=>{
            console.log('Marker click',marker)
          }}
        />
      )})}
     <Box sx={{position:'absolute', left: `${( currentTime / duration) *100 }%` , top:'-0.5rem'}}
        onClick={()=>{
            try{
                // console.log('[current Time]:', currentTime)
                setTimestamp(currentTime)
                setCommentModalOpen(true)
            }catch(err){console.log(err)}
            }}>
                <AddComment   />
            </Box>
    </div>
  );
};

export default CustomProgressBar;