import { Box, Container, Stack, Tooltip, Typography,
  List, ListItem, ListItemText,
  IconButton,
  Button,
 } from '@mui/material'
import AudioPlayer, { RHAP_UI }  from "react-h5-audio-player";
import "react-h5-audio-player/lib/styles.css";
import React,{useEffect, useState, useRef, useContext} from 'react'
import { Link, useParams } from 'react-router-dom';
import Playlist from './Playlist';
import { background, MenuDescendantsProvider } from '@chakra-ui/react';
import Copyright from '../../../components/Copyright';
import useWindowDimensions from "../../../hooks/windowDimensions";
import InfoIcon from '@mui/icons-material/Info';
import CloseButton from '../../../components/CloseButton';
import ShareIcon from '@mui/icons-material/Share';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ShoppingCart from '@mui/icons-material/ShoppingCart';
import PinModal from './PinModal';
import ShareModal from './ShareModal';
import ZoomInIcon from '@mui/icons-material/ZoomIn';

// import albums from '../../../utils/albumList'
import ArrowDropDown from '@mui/icons-material/ArrowDropDown';
import Info from '@mui/icons-material/Info';
import ZoomModal from './ZoomModal';
import * as API from '../../../utils/APICalls'
import * as helpers from '../../../utils/helpers'
import LockoutModal from './LockoutModal';
import UpdateModal from './UpdateModal';
import AddComment from './AddComment';
import CommentModal from './CommentModal';
import ShopModal from './ShopModal';
import { Favorite, FavoriteBorder, PointOfSaleSharp } from '@mui/icons-material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as Brands from '@fortawesome/free-brands-svg-icons'
import SocialLinks from './SocialLinks';
import MusicHeader from './MusicHeader';
import StreamModal from './StreamModal';
import CustomProgressBar from './CustomProgressBar';
import NotificationsIcon from '@mui/icons-material/Notifications';
import ChatModal from './ChatModal';
import TipModal from './TipModal';
import GameModal from './GameModal';
import CheckoutModal from './CheckoutModal';
import { StorefrontContext, StorefrontProvider }  from "../../../context/StorefrontProvider";



  const ShopMusicPlayer = (props) => {
     const { modalOpen, setModalOpen,cart,
        setCart,
        order, view, setView, shop, setShop,
        storefrontData, updateStorefrontData, currentProduct, setCurrentProduct,
        items, setItems,owner, setOwner,cartOpen, setCartOpen,
        subView, setSubView ,isFollower
    } = useContext(StorefrontContext);
    

   const playerRef = useRef(null);
    const  {artist, setArtist,albums, listener,setListener} = props
    // const email = useParams().email?.toString()
    const { trackId , email } = useParams()
    // if(email ==='share'){}
    const [isLoading, setIsLoading] = useState(true);
    const [isLoaded, setIsLoaded ] = useState(false);
    const [isSubscribed, setIsSubscribed] = useState(artist?.subscribers?.includes(listener?._id));
    const [isStreaming, setIsStreaming] = useState(false);
    const [currentTime, setCurrentTime] = useState(0);
    const [ timestamp, setTimestamp ] = useState(0)
    const audioRef = useRef();
    const [ isMuted, setIsMuted ] = useState(false)
    // const [ listener, setListener ] = useState({})
    const [ userLocation, setUserLocation ] = useState(false)
    const [ zoomModalOpen, setZoomModalOpen ] = useState(null)
    const [ updateModalOpen, setUpdateModalOpen ] = useState(false)
    const [ checkoutModalOpen, setCheckoutModalOpen ] = useState(false)
    const [ pinModalOpen, setPinModalOpen ] = useState(false)
    const [ shareModalOpen, setShareModalOpen ] = useState(false)
    const [streamModalOpen, setStreamModalOpen] = useState(false)
    const [commentModalOpen,setCommentModalOpen ] = useState(false)
    const [ shopModalOpen, setShopModalOpen] = useState(false)
    const [ tipModalOpen, setTipModalOpen] = useState(false)
    const [ gameModalOpen,setGameModalOpen] = useState(false)
    const [ chatModalOpen, setChatModalOpen] = useState(false)
    const [ showAlbumInfo, setShowAlbumInfo ] = useState(false)
    const [ hideCopy, setHideCopy ] = useState(false)
    const [ albumNav, setAlbumNav ] = useState(0)
    const [trkNum, setTrkNum ] = useState(0)
      const [duration, setDuration] = useState(0);


      const [ playAlbum, setPlayAlbum ] = useState(albums[albumNav])
      const  album = albums[albumNav]
      const [ playAudio, setPlayAudio] = useState(albums[albumNav]?.tracks?.[trkNum])
      const [ audioObj, setAudioObj ] = useState(albums[albumNav]?.tracks?.[trkNum])
      const [ showHeader, setShowHeader ] = useState(true)
      const [ headerText, setHeaderText ] = useState(`${album?.artist} - ${audioObj?.name}`)
      const { height, width } = useWindowDimensions();
      const [ currentComment, setCurrentComment ] = useState('')

      const [ comments, setComments ] = useState('')
      const [ showComments, setShowComments ] = useState(false)



        useEffect(() => {
          const checkForComment = () =>{
             playAudio?.comments?.forEach((c,i)=>{
               if( Math.round(currentTime) == Math.round(c.time) && currentTime <= Math.round(c.time)+15 ){ 
                setShowComments(true)
                setCurrentComment(c)
                console.log('Comment Popup',c)
                return c
              }else{
                setCurrentComment('')
                setShowComments(false)

              }
            })
     
          }
          checkForComment()
      }, [currentTime]);

      
     

    // const  albumList = albums
  const player = useRef()

  const albumStatusList ={
        'jam':'#9E9E9E',
        'demo':'#9E9E9E',
        'b-side':'#9E9E9E',
        'instrumental':'#FFC107',
        'writing':'#FFEB3B',
        'recording':'#03A9F4',
        'mixing':'#3F51B5',
        'mastering':'#3F51B5',
        'complete':'#CDDC39',
        'touring':'#CDDC39',
        'pre-sale':'#CDDC39',
        'streaming':'#CDDC39',
    }

    const brands = ['facebook','instagram','linkedin','upwork', 'github', 'gitlab', 'twitter', 'bandcamp','soundcloud','spotify','youtube','vimeo','amazon','tidal','youTube',
        'airbnb','uber','lyft','stackoverflow','stackexchange','pinterest','skype','etsy','codepen', 'dropbox', 'googleplay','imdb','npm','meetup','itunes','cashApp','venmo','paypal','pandora','deezer','linktree','xbox','playstation','steam','snapchat','slack','kickstarter','patreon','stripe', 
        'discord','wordpress','tiktok','whatsapp',
        'windows','apple','shopify','twitch','tumblr','trello','unity','wikipedia'
    ]


  const [deviceInfo, setDeviceInfo] = useState({
      userAgent: '',
      platform: '',
      language: '',
      vendor: ''
    });

  useEffect(() => {
    const info = {
      userAgent: navigator.userAgent,
      platform: navigator.platform,
      language: navigator.language,
      vendor: navigator.vendor
    };
    setDeviceInfo(info);
    // console.log('nav info:',info)
  }, []);
 
  
  const colors ={
    main:'#858585',
    accent: '#000',
  }
//  const artist_pic= 'https://res.cloudinary.com/dzxzdsnha/image/upload/v1707683707/Rex_Entropy_Logo_Flat_LG_BLK_zuc1wp.png'
   
   const containerStyles = {
        position:'absolute',
        display:'flex',
        flexDirection:'column',
        justifyContent:'center',

        height:'100%',
        // maxHeight:'800px',
        maxHeight:'95vh',
        width:'100%',
        maxWidth:'600px',
        // fontSize: '0.75rem',
        fontFamily:'Gilroy,sans-serif',
        border:`2px solid ${colors?.main}`,
        borderRadius: '10px',
        padding:'0.5rem',
        marginBottom:'15rem',
        boxShadow:'0px 0px 5px 2px rgba(0, 0, 0, 0.3)',
        // overflow:'hidden',
        // overflowY:'scroll',
    }
  const bannerStyles = {
    position:'relative',
    height: '20vh',
    minHeight:'200px',
    minWidth: '300px',
    width:'99%',
  //  overflow:'hidden',
    border: `1px  solid ${colors?.main}`,
    // boxShadow:'0 0 3px 2px rgba( 0, 0, 0, 0.1)',
    borderRadius: '10px 10px 0px 0px',
    backgroundColor:'red',
    margin:'auto',
    marginTop: '0.5rem',
    boxShadow:'inset 0px -5px 11px 5px rgba(255,255,255,0.5),0px -5px 5px 2px rgba(0,0,0,0.1)',
    backgroundImage:`url("${album?.album_pic[0]}")`,//backgroundAttachment:'fixed',
    backgroundRepeat: 'no-repeat',
    backgroundPositionY:`${album?.album_pic[2]}px`,
    backgroundSize: 'cover',
    backgroundPosition:`fixed`,

  }
   

    const handleCanPlay = () => {
    setIsLoading(false);
    setIsLoaded(true);
  };
  
  const handleWaiting = () => {
    setIsLoading(true);
    setIsLoaded(false);
  };

    const toggleAudio = () =>{
      player.current.audio.current.play();
    }


    const getUserLocation = async () => {
        if (navigator.geolocation) {
          navigator.geolocation.getCurrentPosition(
             async (position) => {
              // console.log('[getUserLocation] position:',position)
              
              const { latitude, longitude } = position.coords;
              setUserLocation({ lat:latitude, lon:longitude });
              // updateAppData({
                //     ...appData,
                //     userLocation,
                // })
                // Send location to backend to find nearby businesses
                // const p = await API.fetchNearbyPlaces(latitude, longitude);
                
              },
              (error) => {
                // setError('Error getting user location: ' + error.message);
                console.log('Error getting user location: ' + error.message)
              },
          {
              enableHighAccuracy: true,
              timeout: 10000,
              maximumAge: 0
          }
      );
    }
  }
  const setURLTrack = async () =>{
    try{
      const urlAlbum = albums?.find((album)=>album?.tracks?.some(track => track._id === trackId)) 
      // const urlAlbum = albums?.filter((album)=>album?.tracks?._id === trackId) 
      const urlTrack = urlAlbum?.tracks?.find((track)=> track?._id === trackId ) 

      setPlayAlbum(urlAlbum)

      setAlbumNav(albums?.indexOf(playAlbum))
      setTrkNum(playAlbum?.tracks?.indexOf(urlTrack))
      setPlayAudio(urlTrack)
     
      // console.log('[setURLTrack] urlAlbum:', urlAlbum)
      // console.log('[setURLTrack] urlTrack:', urlTrack)
      // console.log('[setURLTrack] trkNum:', trkNum)
      // console.log('[setURLTrack] playAlbum:',playAlbum)
      // console.log('[setURLTrack] playAudio:',playAudio)
    }catch(err){console.log('[setURLTrack]',err)}
  }
  
  // const checkForListener = async () =>{
  //   try{
  //     const res = await API.fetchListener(email)
  //     if (res){
  //       setListener(res)
  //       localStorage.setItem('listener', JSON.stringify(res))   
  //     }else if (!res){
  //       const newList =  await API.createListener(email)
  //       if(newList){
  //         setListener(newList)
  //         localStorage.setItem('listener', JSON.stringify(newList))
  //         }
        
  //       }
      
  //     }catch(err){console.log('[saveListen]',err)}
  // }

    const saveListen = async (clicked,type) =>{
      console.log('[Clickd Type]', clicked , type )
      let dur = playAlbum?.tracks[trkNum]?.duration
      if (clicked?.click){
        setDuration(currentTime)
      }
      try{
        const resp = await API.createListen({
          email:email,
          listen:{
            click: type.toString(),
            track:  playAlbum?.tracks[trkNum]?._id,
            title:  helpers.returnTrackTitle(playAlbum?.tracks[trkNum]),
            duration: clicked ? duration : dur,
            coordinates: [userLocation?.lat,userLocation?.lon],
            // ip: ip,
          }
        })
      }catch(err){console.log('[saveListen]',err)}
      
    }

    const handleTrackNext = (click) =>{
          saveListen(click, click?'next':'play through')
          console.log('[TrackNext] Pre-NextAlbum/track :', playAlbum,playAudio)
          try{
            // setTrkNum((trkNum + 1) % playAlbum?.tracks?.length);
            // setAudioObj(playAlbum?.tracks[trkNum+1])
            // setPlayAudio(playAlbum?.tracks[trkNum+1])
            if( trkNum < playAlbum?.tracks?.length -1 ){
              setTrkNum(trkNum+1)
              setAudioObj(playAlbum?.tracks[trkNum+1])
            setPlayAudio(playAlbum?.tracks[trkNum+1])
          }else{
            setTrkNum(0)
            if( albumNav < albums?.length -1 ){
              setPlayAlbum(albums[albumNav+1])
              setAudioObj(albums[albumNav+1]?.tracks[0])
              setPlayAudio(albums[albumNav+1]?.tracks[0])
              setAlbumNav(albumNav+1)
            
              console.log('[TrackNext] Post-NextAlbum/track :', playAlbum,playAudio)
            }else{
              setAlbumNav(0)
              setPlayAlbum(albums[0])
              setAudioObj(albums[0]?.tracks[0])
              setPlayAudio(albums[0]?.tracks[0])
            }




          }
        }catch(err){console.log('[Next Error]:',err)}
      }
    const handleTrackBack = (click) =>{
        const audioElement = playerRef.current?.audio.current;
    if (audioElement && audioElement.currentTime <= 10) {
      audioElement.currentTime = 0;
    }
      // if(currentTime >= 10){
            // playerRef.current.audio.current.currentTime = 0;
          // setAudioObj({track_url:''})
          // setPlayAudio({track_url:''})
          // setAudioObj(playAlbum?.tracks[trkNum])
          // setPlayAudio(playAlbum?.tracks[trkNum])
          // console.log('[playAudio]:', playAudio)
          // return
      // }
      saveListen(click,'back')
         try{
            //  setTrkNum(( trkNum - 1 + playAlbum?.tracks?.length) % playAlbum?.tracks?.length);
            //  setAudioObj(playAlbum?.tracks[trkNum-1])
            //  setPlayAudio(playAlbum?.tracks[trkNum-1])
            if( trkNum >0 ){
              setTrkNum(trkNum-1)
              setAudioObj(playAlbum?.tracks[trkNum-1])
              setPlayAudio(playAlbum?.tracks[trkNum-1])
            }else{
              // setTrkNum(playAlbum?.tracks?.length - 1 )
              //   setAudioObj(playAlbum?.tracks[playAlbum?.tracks?.length - 1])
              // setPlayAudio(playAlbum?.tracks[playAlbum?.tracks?.length - 1])
               if( albumNav === 0 ){
                 let albumsLength = albums?.length - 1
                 let newAlbum = albums[albumsLength]
                 setTrkNum(newAlbum?.tracks?.length -1)
                 setAlbumNav(albumsLength)
                 setPlayAlbum(newAlbum)
                 setAudioObj(newAlbum?.tracks[newAlbum?.tracks?.length -1])
                 setPlayAudio(newAlbum?.tracks[newAlbum?.tracks?.length -1])  
                  console.log('[TrackBack] Post-NextAlbum/track :', playAlbum,playAudio)
               
                }else{
                  setTrkNum(albums[albumNav-1]?.tracks?.length -1)
                  setPlayAlbum(albums[albumNav-1])
                  setAudioObj(albums[albumNav-1]?.tracks[albums[albumNav-1]?.tracks?.length -1])
                  setPlayAudio(albums[albumNav-1]?.tracks[albums[albumNav-1]?.tracks?.length -1])
                  setAlbumNav(albumNav-1)
                }
            }
          }catch(err){console.log('[Back Error]:',err)}
      }

      

      const handleListen = () => {
        if (player.current) {
          setCurrentTime(player.current.audio.current.currentTime);
        }
      };

      const updateUserLikes = async() =>{
        let item = playAudio
        try{
            const likes = listener?.likes
             let index = likes?.indexOf(item?._id)
             if (index > -1) {
                     likes?.splice(index, 1);
                    // }
                }else{
                    //TODO Add listenerId from comment likes list
                    likes.push(item?._id)
  
                }
        const res = await API.updateListener({
            ...listener,
            likes: likes
            })
                if(res){
                    setListener(res)
                }
        }catch(err){
        console.log(err)
      }


    }
const handleSubscribe = async ()=>{
  
  try{
      const subs = listener?.subs?listener?.subs:[]
      let listSubIndex = subs?.indexOf(artist?._id)
      if (listSubIndex > -1) { subs?.splice(listSubIndex, 1); }
      else{subs.push(artist?._id)}

      const subscribers = artist?.subscribers
      let index = subscribers?.indexOf(listener?._id)
      if (index > -1) { subscribers?.splice(index, 1); }
      else{subscribers.push(listener?._id)}

    const res = await API.updateArtist({...artist, subscribers:subscribers})
      if(res){
        setIsSubscribed(res?.subscribers?.includes(listener?._id))
        setArtist(res)
      }else{console.log('[Error Subscribing]')}
  

    const res2 = await API.updateListener({...listener, subs:subs})
      if(res2){
        // setIsSubscribed(res2?.subs?.includes(artist?._id))
        setListener(res2)
      }else{console.log('[Error Subscribing]')}
    
    }catch(err){console.log(err)}
  }
  

       const handleLikeToggle = async (item) =>{
            await updateUserLikes()
           try{
            let list = item?.likes
            let index = list?.indexOf(email)
            //   if(list?.includes(listEmail)){
                //TODO Remove userId from comment likes list
            if (index > -1) {
                    list?.splice(index, 1);
                // }
            }else{
                //TODO Add userId from comment likes list
                list.push(email)  
            }
    
            const res = await API.updateTrack({
                ...item,
                likes: list
            })
            if(res){
                
            }
            }catch(err){
        console.log(err)
      }

    }
  //   const CustomProgressBar = ({ className, ...props }) => (
  //   <div className={`${className} custom-progress-bar`} {...props}>
  //     {playAudio?.comments?.map((comment, index) => (
  //       <div
  //         key={index}
  //         // className="comment-marker"
  //         style={{ 
  //           left: playAudio?.duration ?  `${(comment?.time / playAudio?.duration) * 100}%`:'' ,
  //           top: 0,
  //           bottom: 0,
  //           width: '2px',
  //           backgroundColor: 'red',
  //           zIndex: 2,
          
  //         }}
  //       ></div>
  //     ))}
  //   </div>
  // );
       useEffect(() => {
        if (('mediaSession' in navigator )&& playAudio) {
          navigator.mediaSession.metadata = new window.MediaMetadata({
            title: `${playAlbum?.artist} - ${playAudio?.subtitle ?  playAudio?.name +' '+ playAudio?.subtitle : playAudio?.name }`,
            artist: playAlbum?.artist,
            album: playAlbum?.name,
            artwork: [
              { src: playAlbum?.album_pic, sizes: '512x512', type: 'image/png' }
            ]
          });

          navigator.mediaSession.setActionHandler('previoustrack', () => {
            // settrkNum((trkNum - 1 + tracks.length) % tracks.length);
            handleTrackBack({click:true})
          });
          
          navigator.mediaSession.setActionHandler('nexttrack', () => {
            handleTrackNext({click:true})
            // settrkNum((trkNum + 1) % tracks.length);
          });
        }
      }, [trkNum]);



      // useEffect(() => {
      //   console.log('[Checking for listener]');
      //   if (email) {
      //     checkForListener(email);
      //   }
      // }, [email]);

      useEffect(() => {
        console.log('[Checking for listener]');
        if (trackId) {
          setURLTrack(trackId);
        }
      }, [trackId]);

      useEffect(() => {
        if (playerRef.current) {
          const audio = playerRef.current.audio.current;
          const handleLoadedMetadata = () => {
            console.log('Audio duration loaded:', audio.duration);
            setDuration(audio.duration);
          };
          audio.addEventListener('loadedmetadata', handleLoadedMetadata);
          return () => audio.removeEventListener('loadedmetadata', handleLoadedMetadata);
        }
      }, []);

    useEffect(() => {
        const handleBeforeUnload = (event) => {
          event.preventDefault();
          saveListen(true,'page leave')
          event.returnValue = ''; // This is required for some browsers to show the alert
        };

        window.addEventListener('beforeunload', handleBeforeUnload);

        return () => {
          window.removeEventListener('beforeunload', handleBeforeUnload);
        };
      }, []);

      useEffect (()=>{

      },[audioObj])

      useEffect (()=>{
       
        // console.log('listener:', listener)
      },[listener])

      useEffect (()=>{

        console.log('playAudio:', playAudio)
      },[playAlbum, playAudio,trkNum])

      useEffect (()=>{

      },[showHeader])
      
      useEffect (()=>{

      },[artist])
      
     
      useEffect (()=>{

        getUserLocation()

      },[])

//         if (loading) {return <div><h2>sorry</h2></div>;}
// else if(!loading && albums){
// if((!helpers.validateEmail(listener?.email) && !listener?.isVerified)||email==='share'){
//   return (<LockoutModal lock={helpers.validateEmail(email)?false:true} />)
// }
// else{

  return (
    <Container
      className='center'
      sx={containerStyles}
      >
      
        <UpdateModal 
          email={email}
          setListener={setListener}
          listener={listener}
          updateModalOpen={updateModalOpen} 
          setUpdateModalOpen={setUpdateModalOpen} />
    
       <Tooltip title='share'>
          <Button 
          size='small'
            sx={{
              zIndex:999,
              fontSize:'0.65rem',
             position:'absolute',
              top:'0.5rem',
              left:'0.5rem',
            display:'flex',
              // borderRadius:'5px',
              borderRadius:'50px 10px 50px 10px',
              width:'fit-content',
              height:'1.5rem',
              // backgroundColor:'#FFF',
              alignContent:'center',
              alignItems:'center',
              justifyContent:'center',
              padding:'0.5rem',
              // color:'#858585',
              color:'rgba(252, 237, 68)',
              border:'1px solid rgba(252, 237, 68)',
              boxShadow:'0 0 3px 2px rgba(252, 237, 68,1), inset 0 0 3px 1px rgba(252, 237, 68,0.5)',
              '&:hover': {background:'rgba(144, 254, 195,0.5)'},
              '&:active': {boxShadow:'inset 0 0 3px 2px rgba( 0, 0, 0, 0.1)'},
            }}
              onClick={(e)=>{
                e.preventDefault()
                setShareModalOpen(true)
              }} 
              >
            <ShareIcon 
              className='button-woddle'
              sx={{cursor:'pointer',fontSize:'0.9rem',}}
              />
              Share
          </Button>
        </Tooltip >

        <Tooltip title='merch'>
          <Link to='https://parallaxr.shop' target='_blank'>
            <Button 
            variant='outlined'
            size='small'
            sx={{
              zIndex:999,
              fontSize:'0.65rem',
              position:'absolute',
              top:'2.5rem',
              left:'0.5rem',
              display:'flex',
              color:'#858585',
              borderRadius:'10px 50px 10px 10px',
              // width:'2rem',
              height:'1.5rem',
              alignContent:'center',
              alignItems:'center',
              justifyContent:'center',
              padding:'0.5rem',
              color:'rgba(252, 237, 68)',
              border:'1px solid rgba(252, 237, 68)',
              boxShadow:'0 0 3px 2px rgba(252, 237, 68,1), inset 0 0 3px 1px rgba(252, 237, 68,0.5)',
              // border:'1px solid #757575',
              // backgroundColor:'#FFF',
              // boxShadow:'0 0 3px 2px rgba( 0, 0, 0, 0.1), inset 0 0 3px 1px rgba( 0, 0,0,0.3)',
              '&:hover': {background:'rgba(144, 254, 195,0.5)'},
              '&:active': {boxShadow:'inset 0 0 3px 2px rgba( 0, 0, 0, 0.1)'},
            }}
                onClick={(e)=>{
                  //   e.preventDefault()
                  // setShopModalOpen(true)
                  
                }} 
                
                >
              <PointOfSaleSharp 
              className='button-woddle'
              sx={{cursor:'pointer' ,fontSize:'0.9rem',}}
              />
              Merch
            </Button>
          </Link>
        </Tooltip >
        
      

         <Tooltip title='subscribe'>
          {/* <Link to='https://parallaxr.shop' target='_blank'> */}
            <Button 
            variant='outlined'
            size='small'
            sx={{
              zIndex:999,
              fontSize:'0.65rem',
              position:'absolute',
              top:'4.5rem',
              left:'0.5rem',
              display:'flex',
              // color:'#858585',
              borderRadius:'10px 50px 10px 50px',
              // width:'2rem',
              height:'1.5rem',
              alignContent:'center',
              alignItems:'center',
              justifyContent:'center',
              padding:'0.5rem',
              color: isSubscribed?'rgba(221, 43, 252)':'rgba(252, 237, 68)',
              // border:`1px solid ${!isSubscribed?'rgba(221, 43, 252)':'rgba(252, 237, 68)'} `,
              border:`1px solid ${isSubscribed?'rgba(221, 43, 252)':'rgba(252, 237, 68)'} `,
              boxShadow:`0 0 3px 2px ${isSubscribed?'rgba(252, 237, 68)':'rgba(252, 237, 68)'}, inset 0 0 3px 1px rgba(252, 237, 68,0.5)`,
              // border:'1px solid #757575',
              // backgroundColor:'#FFF',
              // boxShadow:'0 0 3px 2px rgba( 0, 0, 0, 0.1), inset 0 0 3px 1px rgba( 0, 0,0,0.3)',
              '&:hover': {background:'rgba(144, 254, 195,0.5)'},
              '&:active': {boxShadow:'inset 0 0 3px 2px rgba( 0, 0, 0, 0.1)'},
            }}
                onClick={(e)=>{
                  //   e.preventDefault()
                  // setShopModalOpen(true)
                  handleSubscribe()
                  
                }} 
                
                >
              <NotificationsIcon
              className='button-woddle'
              sx={{cursor:'pointer' ,fontSize:'0.9rem',}}
              />
              subscribe{isSubscribed?'d':''}
            </Button>
          {/* </Link> */}
        </Tooltip >
       

        <CommentModal
            setCommentModalOpen={setCommentModalOpen} 
            commentModalOpen={commentModalOpen}
            track={playAlbum?.tracks[trkNum]}
            playAlbum={playAlbum}
            playAudio={playAudio}
            timestamp={timestamp}
            trkNum={trkNum}
            listener={listener}
          />
      
        
        <ShareModal 
          listener={listener}
          setListener={setListener}
          shareModalOpen={shareModalOpen} 
          validateEmail={helpers.validateEmail}
          setShareModalOpen={setShareModalOpen}/>

   

                      <MusicHeader
                       setCheckoutModalOpen={setCheckoutModalOpen}
                       checkoutModalOpen={checkoutModalOpen}
                        listener={listener} 
                        email={email}
                        colors={colors}
                        setUpdateModalOpen={setUpdateModalOpen}
                        albums={albums}
                        albumNav={albumNav}
                        setAlbumNav={setAlbumNav}
                        isStreaming={isStreaming} setIsStreaming={setIsStreaming}
                        setStreamModalOpen={setStreamModalOpen}
                     tipModalOpen={tipModalOpen }
                     setTipModalOpen ={setTipModalOpen }
                     chatModalOpen={chatModalOpen }
                     setChatModalOpen ={setChatModalOpen }
                     />

                
                  <PinModal pinModalOpen={pinModalOpen} setPinModalOpen={setPinModalOpen} item={currentProduct}/>
                <CheckoutModal cart={cart} 
                  listener={listener}
                  checkoutModalOpen={checkoutModalOpen}  
                    setCheckoutModalOpen={setCheckoutModalOpen}/>

        {/* <Box  sx={{cursor:'pointer',
            position:'absolute',right:'0.5rem',top:'0.5rem'}} 
         onClick={()=>{
            try{
              console.log('[current Time]:', currentTime)
              setTimestamp(currentTime)
              setCommentModalOpen(true)
            }catch(err){console.log(err)}
                }}  >

              <AddComment 
               />
        </Box> */}
{/* //********************
//*   Player Section
//************** */}
        <Container 
        sx={{position:'absolute',
          minHeight:'300px',
          height:'70vh',
          //flex:1,
          left:'0',
          // top:"20vh",
          top:"22.5vh",
          padding:'0.5rem',
          zIndex:0,
          overflow:'hidden', 
          overflowY:'scroll',
          backgroundImage:`linear-gradient(to top, #ffffff, #ffffff, #ffffff, #ffffff00, #ffffff00);
  );`
            }}>

          {( !showHeader && showComments ) && <Box sx={{
                position:'absolute',     
                left:'50%',
                top:'10vh', 
                transform:'translate(-50%,30%)',maxWidth:'80%', minWidth:'150px',width:'fit-content', height:'fit-content',
                padding:'0.5rem', boxShadow:'0 0 3px 2px rgba( 0, 0, 0, 0.1), inset 0 0 3px 1px rgba( 0, 0,0,0.3)',
                border:'2px solid #000',//borderBottom:'none', 
                borderRadius:'5px 50px 50px 50px ',
                backgroundColor:'#FFF', textAlign:'center',alignContent:'center',
                zIndex:9999,
                }}>
                  <Typography variant="caption" sx={{textAlign:'left'}}>
                  <b>{helpers.secsToMins(currentComment?.time)}s</b> 
                  </Typography>
                  <Typography variant="body1">
                    "{currentComment.comment}"
                  </Typography>
                  <Typography variant="caption" sx={{textAlign:'right'}}>
                  <b>-{currentComment?.listener?.name?currentComment?.listener?.name:'Anonymous'}</b>
                  </Typography>
                  <br/>
                  <Typography variant="caption" sx={{textAlign:'right'}}>
                  {currentComment?.date ? helpers.getDisplayDate(currentComment?.date) :'-'}
                  </Typography>
            {/* {currentComment} */}

          </Box>}
          
          {/* <section style={{position:'sticky',top:0,left:0,height:'100%'}}> */}

                {!showHeader&&<ArrowDropUpIcon
                    className='button-woddle'
                    sx={{color:'#FFF', 
                    border:`7px solid ${showHeader ? '#000':'#FFF'}`, 
                    borderRadius:'50%',
                    backgroundColor:colors.accent,
                     boxShadow:'inset 0 0 5px 1px rgba( 0,0,0,0.2), 0px 2px 5px 1px rgba(0,0,0,0.2) ',
                    // boxShadow:'inset 0 0 3px 1px rgba( 255, 255,255,0.5),',
                    position:'absolute',
                    top:'2.35rem',
                    left:'0.75rem',
                    cursor:'pointer',
                    zIndex:9999999
                  }}
                  onClick={()=>{
                    setShowHeader(!showHeader)
                  }}
                  />
              
                // <Box sx={{position:'absolute', bottom:'-1rem',
                //     right:'-0.125rem',
                //     }}>
                // <ArrowDropDownIcon onClick={()=>{setShowHeader(!showHeader)}}/>
                // </Box>
                }

            {showHeader && 
            <Box sx={bannerStyles}
            // src={artist_pic}
            >
              <ZoomInIcon
                  className='button-woddle'
                  sx={{color:'#FFF', 
                    fontSize:'0.75rem',
                  border:'3px solid #FFF', 
                  borderRadius:'50%',
                  backgroundColor:colors.accent,
                  position:'absolute',
                  bottom:'0.5rem',
                  left:'0.5rem',
                  cursor:'pointer',
                  opacity:'0.75',
                  padding:'0.2rem',
                  zIndex: 9999
                }}
                onClick={()=>{
                  setZoomModalOpen(true)
                  console.log('showHeader:',showHeader)
                }}
                />
                  <ZoomModal setZoomModalOpen={setZoomModalOpen} zoomModalOpen={zoomModalOpen} src={album?.album_pic[0]}/>

                {showHeader?<ArrowDropDownIcon
                    className='button-woddle'
                    sx={{color:'#FFF', 
                    border:'7px solid #FFF', 
                    borderRadius:'50%',
                    backgroundColor: colors.accent,
                    position:'absolute',
                    top:'-1rem',
                    left:'-0.125rem',
                    cursor:'pointer',
                    zIndex: 9999,
                    boxShadow:'inset 0 0 5px 1px rgba( 0,0,0,0.2), 0px 2px 5px 1px rgba(0,0,0,0.2) ',
                  }}
                  onClick={()=>{
                    setShowHeader(!showHeader)
                    console.log('showHeader:',showHeader)
                  }}
                  />
                :
                <Box sx={{position:'absolute', bottom:'-1rem',
                    right:'-0.125rem',
                    }}>
                <ArrowDropUpIcon 
                  sx={{boxShadow:'inset 0 0 5px 1px rgba( 0,0,0,0.2), 0px 2px 5px 1px rgba(0,0,0,0.2) ',}}
                onClick={()=>{setShowHeader(!showHeader)}}/>
                </Box>
                }
          {/* ----------ALBUM HEADER-------- */}
                <Stack sx={{gap:'0.25rem', 
                  height:'5rem',
                  borderRadius:'10px 10px 0 0',margin:'auto',
                  padding:'0.25rem',
                  boxShadow:'inset 0 0 5px 1px rgba( 0,0,0,0.2), 0px 2px 5px 1px rgba(0,0,0,0.2) ',
                  backgroundColor:'rgba(255,255,255,1)',
                  // borderBottom:'2px solid #858585',
                  backdropFilter:'blur(5px)'
                  }}>

                  <Typography  sx={{fontFamily:'Gilroy, sans-serif', fontSize:'1rem',fontWeight:'bold',margin:'0 auto'}}>
                    {album?.title}  
                  </Typography>
                  <Typography  sx={{fontFamily:'Gilroy, sans-serif', fontSize:'0.8rem',fontWeight:'normal', marginTop: '-0.5rem'}}>
                    {album?.subtitle? album?.subtitle:<>&nbsp;</>}  
                  </Typography>
                  <Typography  sx={{fontFamily:'Gilroy, sans-serif', fontSize:'0.8rem',fontWeight:'normal', marginTop: '-0.5rem'}}>
                    {album?.year}  
                  </Typography>
                   { helpers?.hasValue(albums[albumNav]?.streaming) ? <Box sx={{margin:'auto',  
                      padding:'0 0.125rem',borderRadius:'5px 5px ',
                      border:'1px solid #757575',
                      backgroundColor:'#FFF',
                      boxShadow:'inset 0 0 5px 1px rgba( 0,0,0,0.2), 0px 2px 5px 1px rgba(0,0,0,0.2) ',
                      borderBottom:'none'
                      }}>
                      <SocialLinks albums={albums} albumNav={albumNav} />
                    </Box>:''}
                </Stack>
          {/* <Box  sx={{color:'#FFF', 
              
                borderRadius:'10px',
                backgroundColor:colors.accent,
                cursor:'pointer'}}> */}
                  <Typography variant='p'
                    sx={{color: albumStatusList[albums[albumNav]?.status], 
                        position:'absolute',
                        top:'2.5rem',
                        left:'0.5rem',
                        padding:'0rem 0.125rem',
                        width:'fit-content',
                        height:'0.95rem',
                        minWidth: '100px',
                        fontSize:'0.8rem',
                        // backgroundColor:'rgba(176,255,252,0.5)',
                      
                        // backdropFilter:'blur(5px)',
                        backgroundColor:'rgba(255,255,252,1)',
                        boxShadow:`inset 0 0 3px 1px ${albumStatusList[albums[albumNav]?.status]}80,0px 0px 5px 2px ${albumStatusList[albums[albumNav]?.status]}40`,
                        border:`1px solid ${albumStatusList[albums[albumNav]?.status]}`, 
                        borderRadius:'10px'}}>
                          <b>status</b> | <span style={{color:"#000"}}>{albums[albumNav]?.status}</span>
                    </Typography>

                  
          <Stack 
            sx={{
            position:'absolute',
            top:'1rem',
            right: '0.5rem',
            gap:'0.25rem',
              fontSize:'0.75rem', alignItems:'center', cursor:'pointer'}}
              onClick={(e)=>{
                  setCurrentProduct(albums[albumNav])
                   updateStorefrontData({
                    ...storefrontData, 
                    currentProduct
               })
                  setPinModalOpen(true)
              }}
              >
              

              <ShoppingCart 
                  className='button-woddle'
                  sx={{fontSize:'1rem', color:colors?.main}}
                  onClick={()=>{
                    setCurrentProduct(albums[albumNav])
                    updateStorefrontData({
                    ...storefrontData, 
                    currentProduct
               })
                    setPinModalOpen(true)
                  }}

                  />
            $ {album?.price} USD
            
          </Stack>
          {showComments &&<Box  sx={{position:'absolute', left:'50%',bottom:'-0.25rem', 
                transform:'translate(-50%,0%)',minWidth:'150px',maxWidth:'80%', width:'fit-content', height:'fit-content',
                  padding:'0.5rem', boxShadow:'0 -2px 3px 1px rgba( 0, 0, 0, 0.2), inset 0 0 3px 1px rgba( 0, 0,0,0.3)',
                  border:'2px solid #000',
                  // borderBottom:'none', 
                  // borderRadius:'10px 10px 0 0',
                   borderRadius:'50px 50px 50px  5px',
                  backgroundColor:'#FFF', textAlign:'center',alignContent:'center',
                  zIndex:9999,
                }}>
                  <Typography variant="caption" sx={{textAlign:'left'}}>
                  <b>{helpers.secsToMins(currentComment?.time)}s</b> 
                  </Typography>
                  <Typography variant="body1">
                    "{currentComment.comment}"
                  </Typography>
                  <Typography variant="caption" sx={{textAlign:'right'}}>
                  <b>-{currentComment?.listener?.name?currentComment?.listener?.name:'Anonymous'}</b>
                  </Typography>
                  <br/>
                  <Typography variant="caption" sx={{textAlign:'right'}}>
                  {currentComment?.date ? helpers.getDisplayDate(currentComment?.date) :'-'}
                  </Typography>
            {/* {currentComment} */}

          </Box>}

        
              {!showAlbumInfo?<InfoIcon 
                className='button-woddle'
                sx={{color:'#FFF', 
                border:'1px solid #FFF', 
                borderRadius:'50%',
                backgroundColor:colors.accent,
                position:'absolute',
                bottom:'0.5rem',
                right:'0.5rem',
                cursor:'pointer',
              }}
              onClick={()=>{
                setShowAlbumInfo(true)
              }}
              />
            :
            <Box sx={{position:'absolute', bottom:'-1rem',
                right:'-0.125rem',
                }}>
            <CloseButton func={setShowAlbumInfo}/>
            </Box>
            }
            </Box>}

            {showAlbumInfo &&<Box sx={{minHeight:'20vh', maxHeight:'50vh',
              overflowY:'scroll',padding:'0.5rem',
              justifyContent:'center', alignContent:'center'}}>
              {album?.info}
            </Box>}

         
        <Stack sx={{position:'sticky', top:0 ,
            zIndex:99999,
          }}>
             <CustomProgressBar className='custom-progress-bar'
                setTimestamp={setTimestamp}
                setCommentModalOpen={setCommentModalOpen}
                currentTime={currentTime}
                markers={playAudio?.comments}
                duration={playAudio?.duration}
                /> 
<div className={`audio-player-container ${isLoaded ? 'loaded' : ''}`}>

          <AudioPlayer
            ref={player}
            onListen={handleListen}
            listenInterval={1000}
            className="player"
            onEnded={()=>handleTrackNext({click:false})}
            onClickNext={()=>handleTrackNext({click:true})}
            onClickPrevious={()=>handleTrackBack({click:true})}
            onCanPlay={handleCanPlay}
            onWaiting={handleWaiting}
           
            autoPlayAfterSrcChange 
            // src={playAudio?.track_url}
            src={playAlbum?.tracks?.length ? playAlbum?.tracks[trkNum]?.track_url:''}
            showJumpControls={false}
            // layout="stacked-reverse"
            layout="stacked"
            // RHAP_UI.PROGRESS_BAR,
            //  customProgressBarSection={[
            //   RHAP_UI.CURRENT_TIME,

            //   (props) => (
            //     <CustomProgressBar
            //     {...props}
            //     className='custom-progress-bar'
            //       markers={playAudio?.comments}
            //       duration={playAudio?.duration}
            //     />
            //   ),
            //   RHAP_UI.DURATION,
            // ]}
            customControlsSection={[<Box sx={{display:'flex',
            alignContent:'center', alignItems:'center',
            textAlign:'center',justifyContent:'center',
            }}
            // >
            // <span 
            onClick={()=>handleLikeToggle(playAudio)}>
                 {playAudio?.likes?.length}&nbsp;{!playAudio?.likes?.includes(email)?
              <Tooltip title='like'>
                <FavoriteBorder />
              </Tooltip>
            :
            <Tooltip title='liked'>
                <Favorite sx={{color:'red'}}/>
            </Tooltip>
            
            }
          
         </Box>,
         <Box onClick={()=>{
                try{
                  console.log('[current Time]:', currentTime)
                  setTimestamp(currentTime)
                  setCommentModalOpen(true)
                }catch(err){console.log(err)}
                }}>
                  <AddComment   />
              </Box>
              ,
              "LOOP",
              "MAIN_CONTROLS", "VOLUME_CONTROLS"]}
      
            muted={isMuted}
            showSkipControls
            showDownloadProgress ={true}
            // crossOrigin = {'anonymous'}
            crossOrigin = {window?.location?.href?.includes('://localhost:')?false:'anonymous'}
            onError={e => console.log("[ -----Audio------- ]Error: ",e)}
            header={playAlbum ? `${playAlbum?.artist} - ${playAudio?.subtitle ?  playAudio?.name +' '+ playAudio?.subtitle : playAudio?.name }`:`Select a track!`}
            // header={audioObj?.name ? headerText : album?.artist}
            style={{
              backgroundColor:'rgba(255,255,255,0.7 )',
              backdropFilter:'blur(5px)',
              borderRadius:'0 0 10px 10px', 
              boxShadow:'inset 0 0 5px 1px rgba( 0,0,0,0.2)'//, 0px 2px 5px 1px rgba(0,0,0,0.2) '
              ,
              width:'100%',
              border:'1px solid #757575',
              position:'sticky',top:'0px', zIndex:999}}
          />
</div>
</Stack>
        
    {isLoading && <p style={{margin:'auto'}}>Loading...</p>}  
            <Playlist album={album} 
              duration={currentTime}
              saveListen={saveListen}
              setAudioObj={setAudioObj}
              audioObj={audioObj}
              playAudio={playAudio}
              toggleAudio={toggleAudio}
              setPlayAudio={setPlayAudio}
              playAlbum={playAlbum}
              setPlayAlbum={setPlayAlbum}
              albums={albums}
              setTrkNum={setTrkNum}
              trkNum={trkNum}
              albumNav={albumNav}
              setAlbumNav={setAlbumNav}
              listEmail={email}
              colors={colors} 
              listener={listener}
              setListener={setListener}
              setShareModalOpen={setShareModalOpen}
              shareModalOpen={shareModalOpen}
              sx={{backgroundColor:'#fff'}}
              />
              
        </Container>
        
        {/* </section> */}
       <Box sx={{
        display:'flex',
        flexDirection:'column',
         position:'fixed',
         bottom:'0',
         left:'50%',
         transform:'translate(-50%,20%)',
         width:'80%',
         height:hideCopy? '0':'8.5rem'
        }}>

         <Box sx={{position:'relative',alignSelf:'start',width:'100%', }} onClick={()=>{
           setHideCopy(!hideCopy)
         }}>

          {!hideCopy ? 
          <Box sx={{position:'absolute',left:'0.5rem',top:'0.5rem', zIndex:999}}>
            <CloseButton /> 
          </Box>
          : <Box sx={{
            display:'flex',
            position:'absolute',
            bottom:'-2px',
            left:'50%',
            transform:'translate(-50%,0)',
            border:'2px solid #858585',
            padding:'0.25rem 1.25rem',
            backgroundColor:'#fff',cursor:'pointer',
            alignItems:'center',
            boxShadow: '0px -1px 7px 2px rgba(0,0,0,0.2)',
            borderRadius:'5px 5px 0px 0px'}}>
              <ArrowDropUpIcon />
            about us
            </Box>}
         </Box>
         
         {!hideCopy && <Copyright view='signupSheet' 
         hide={hideCopy}
         color={colors.main}
         shadow={true}
         /> }
        </Box>
          <ShopModal
           setShopModalOpen={setShopModalOpen} 
             shopModalOpen={ shopModalOpen }
            track={playAlbum?.tracks[trkNum]}
            listener={listener}
          />
          <StreamModal  
            listener={listener}
            artist={artist}
            setStreamModalOpen={setStreamModalOpen} 
            streamModalOpen={streamModalOpen}
           isStreaming={isStreaming} setIsStreaming={setIsStreaming}
          />
          <GameModal  
            listener={listener}
            gameModalOpen={gameModalOpen}
            setGameModalOpen={setGameModalOpen} 
          
          />
             <ChatModal 
            chatModalOpen={chatModalOpen}
            setChatModalOpen={setChatModalOpen}
          />
             <TipModal
            tipModalOpen={tipModalOpen}
            setTipModalOpen={setTipModalOpen}
          />
    </Container>
  )
}
// }
// }

export default ShopMusicPlayer