import { Box, Button, Card, Container, Paper, Typography,Modal, Stack, Divider } from '@mui/material'
import React, { useState, createContext ,useContext, useEffect} from "react";
import { StorefrontContext, StorefrontProvider }  from "../../context/StorefrontProvider";
import { QRCodeContext } from "../../context/QRCodeProvider"
import CloseIcon from '@mui/icons-material/Close';
import useWindowDimensions from "../../hooks/windowDimensions";

import * as API from '../../utils/APICalls'
import * as Order from '../../utils/OrderLogic'
import * as helpers from '../../utils/helpers'
import CreateHMNIStickerAsset from '../../components/CreateHMNIStickerAsset';
import CollectNametagText from './CollectNametagText';


const AddToCartButton = (props) => {
  const item = props.item
  const { modalOpen, setModalOpen, defaultQR, currentProduct,
          setCurrentProduct,buildStep, setBuildStep,orderDefault,
          cart,setCart,currentOrder, setCurrentOrder,shop, selectedSize,storefrontData, 
          framingOpen,setFramingOpen,createCart, generateOrder,createOrder,
          updateStorefrontData} = useContext(StorefrontContext);
const { qrCodeData, updateQRCodeData, setQRCodeData } = useContext(QRCodeContext);
  const [ loading, setLoading ] = useState(false)
  const [ geo, setGeo ] = useState(false)
  const { height, width } = useWindowDimensions();
//  const [ framingModalOpen, setFramingOpen ] = useState(false)

  // order = { shop:"",
  //   cart_id:"",
  //   itemName:"",
  //   size:"",
  //   shirtType:"Bella + Canvas 3100",
  //   shirtColor:"white",
  //   printFile:"",
  //   notes:"",
  //   qr_id:"",
  //   qr_pin:"",
  //   shippingAddress: {},
  //   isComplete: false,
  //   isFulfilled: false,
  //   isShipped: false,
  //   isActivated: false,
  //   owner: ""}

  const itemNameFilter = (item,term) =>{
         return item?.name?.includes(term);
     }

  const generatecart_id = async (hexString) =>{
    hexString = await helpers.generateRandomHex(16);
    try{
      const res = await API.cartById(hexString)
      if(!res){
        console.log(hexString)
         return hexString
      }else{
        await generatecart_id(hexString)
      }
    }catch(err){console.log(err)}

  }

    const resetOrder = async() =>{
    //!---Reset QR or something for new orders
    try{
      // if(qrCodeData){
       setQRCodeData(defaultQR)
      //  updateQRCodeData(qrCodeData)
      // }
      // await setCurrentOrder({})
      //  setBuildStep(null)
      setCurrentOrder()
      setCurrentProduct(item)
      updateStorefrontData({
          ...storefrontData,
          currentOrder,
          currentProduct
          // buildStep
      })
      console.log('[AddToCart] post-order click [Storefront]:', storefrontData)
      console.log('[AddToCart] post-order click [qrCodeData]:', qrCodeData)
    } catch(err){console.log(err)}
    if(!qrCodeData?._id && !currentOrder?.qr_pin && buildStep === null){
      return true
    }else{return false}
  }

// const addToCart = async (cart,obj) =>{
//     let tempCart = cart?.items
//     tempCart?.push(obj)
//     const {res} = await API.updateCart({...cart,items: tempCart})
//     if (res){
//         console.log('[addToCart]--res:',res )
//         setCart(res)
//         updateStorefrontData({
//             ...storefrontData,
//             cart
//         })
//     }
//   }


  // const createCart = async () =>{
  //   try{
  //     const init = ['init']
  //       const res = await API.createCart({
  //         shop: shop?._id,
  //         owner: shop?.owner?._id,
  //         status:'draft',
  //         isComplete: false,
  //       })
  //       if(res[0]?._id){
  //         console.log('[createCart] res:',res[0])
  //         setCart(res[0])
  //         setCurrentOrder({
  //           ...currentOrder,
  //           cart_id: res[0]?._id
  //         })
  //         updateStorefrontData({
  //           ...storefrontData,
  //           cart
  //         })
  //        generateOrder()
  //       }
  //   }catch(err){console.log('[createCart] error:',err)}
  // }

  // const generateOrder = async (geoD) => {
  //   console.log('[AddToCart] pre-order click [Storefront]:', storefrontData)
  //   console.log('[AddToCart] pre-order click [qrCodeData]:', qrCodeData)
  //   // const cart_id = cart?.length > 0 ? cart[0]?.cart_id : await generatecart_id()
  //   var cart_id 
    
      
      
  //   try{
  //       const baseOrder = {
  //         shop: shop?._id,
  //         shopName: shop?.operating_name,
  //         cart_id: cart?._id,
  //         itemName: item?.name,
  //         item: item?._id,
  //         type: item?.tags?.includes('music') ? 'music':'shirt',
  //         size: item?.tags?.includes('music') ? "n/a" : selectedSize,
  //         price: item?.price,
  //         shirtType: item?.tags?.includes('music') ? "n/a" : "Bella + Canvas 3100" ,
  //         shirtColor: item?.tags?.includes('music') ? "n/a" : "white",
  //         printBase: item?.tags?.includes('music') ? "n/a": item?.printBase_url[0],
  //         printFile: helpers.itemNameFilter(item,"[ Base ]") ? item?.printBase_url[0] : item?.type === 'music'?'n/a':null,
  //         notes:"",
  //         qr_id: helpers.itemNameFilter(item,"[ Base ]") || item?.tags?.includes('music') ?"n/a":"",
  //         qr_pin: helpers.itemNameFilter(item,"[ Base ]") || item?.tags?.includes('music') ?"n/a":"",
  //         qr_pic: helpers.itemNameFilter(item,"[ Base ]") || item?.tags?.includes('music') ?"n/a":"",
  //         asset_url: helpers.itemFieldFilter(item,'tags','nameTag')?'':'n/a',
  //         asset_text: !helpers.itemFieldFilter(item,'tags','nameTag')?'n/a': currentOrder?.asset_text ? currentOrder?.asset_text : [],
  //         shippingAddress: {},
  //         status: "draft",
  //         // isComplete: true, 
  //         isFulfilled: false,
  //         isShipped: false,
  //         isActivated: false,
  //         owner: shop?.owner?._id,
  //         proof: item?.images[0],
  //         ip: ''//geoD?.IPv4
  //         // const getGeo = ()=>{

  //         //   return  geo?.IPv4
  //         // }

  //       }

  //       // await setCurrentOrder(baseOrder)
  //       // await updateStorefrontData({
  //       //     ...storefrontData,
  //       //       currentOrder
  //       //   })
          
  //         // console.log('[currentOrder]:',currentOrder)
  //       // await routeOrder(baseOrder)
  //         await createOrder({
  //             ...baseOrder,
  //             isComplete: item?.tags.includes('music') ? true : false
  //           })
       
  //     }catch(err){console.log('[Order generation failed]: ',err)}
        
  //   }

  // const createOrder = async (ord) =>{
  //   try{

  //     const order = await API.createOrder(ord)
  //     if (order){
  //       await addToCart(cart,order)
  //        console.log('[ BuildShirt] (Create Order) response:',order)
  //        setCurrentOrder(order)
  //        updateStorefrontData({
  //         ...storefrontData,
  //           currentOrder
  //        })
  //     }
  //   }catch(err){console.log('[createOrder] Error:',err)}
  // }
   

  const handleNametagAdd = async () =>{
    try{
      setFramingOpen(true)
     
    }catch(err){console.log('[handleNametagAdd] Error:',err)}
  }
   

  const processRoute = async(item,geoD) => {
      const stickerList = ["The Gridwalker","Sage","Hello My Name is","GloAun"]
      try{

        await setCurrentProduct(item)
        await updateStorefrontData({
         ...storefrontData,
           currentProduct
        })
       switch (true) {
             case itemNameFilter(item,"[ Base ]"):
                 
                 generateOrder(geoD,shop,item,selectedSize,)
                 break;
             case item?.name === "Porthole To My Heart":
                 try{
                  generateOrder(geoD,shop,item,selectedSize,)
                   
                 }catch(err){console.log(err)}
               break;
               case stickerList?.includes(item?.name):
                 try{
                   generateOrder(geoD,shop,item,selectedSize,)
                 }catch(err){console.log(err)}
                 
                 
                 break;
              
             default:
                 console.log('no process established');
         }
      }catch(err){console.log(err)}
    }

    const handleAdd = async(obj)=>{
      // e.preventDefault()
      try{
        if( !cart?._id ){
          // await setCurrentProduct(item)
          await updateStorefrontData({
            ...storefrontData,
            cart,
            currentProduct
        })
          await createCart(item)
        }else if(cart?._id){
            await generateOrder(cart,item)
           
          }
          else{return}
          // }else{console.log('[Not Reset 🤷]')}
        }catch(err){console.log('[handleAdd] err:',err)}
      }

   useEffect(()=>{

      // console.log('[Build Step]: ',buildStep)
  
    },[currentOrder,item,shop,qrCodeData,currentProduct])

  return (
    <>
    <Button variant='outlined' 
      disabled={loading}// || (modalOpen && currentOrder?.cart_id)}
      sx={{borderColor: '#757575', color:'#000'}} onClick={(e)=>{
        try{
          setCurrentProduct(item)
          updateStorefrontData({
              ...storefrontData,
                currentProduct
              })
            if(!helpers.itemFieldFilter(item,'tags','nameTag')){
                handleAdd(item)
              }else{
                handleNametagAdd(item)
              }

        }catch(err){console.log(err)}
      }}>{props.view !== 'product-view'?'Quick Add':item?.tags.includes('basic')||item?.tags.includes('music')?'Add to Cart':'Build Shirt'}</Button>
    
   
    </>
  )
}

export default AddToCartButton